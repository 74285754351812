<!-- 作品详情术语分类 -->
<template>
  <div>
    <div class="actions flex jc-end mb-4">
      <div style="flex:1" v-has="'production:term:note:search'">
        <div style="width:500px">
          <a-row type="flex" :gutter="16">
            <a-col>
              <a-input v-model="searchText" @pressEnter="searchQuery" placeholder="请输入搜索内容" style="width:300px;"></a-input>
            </a-col>
            <a-col>
              <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
            </a-col>
          </a-row>
        </div>
      </div>
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'production:term:note:delete'"
        >删除</a-button
      >
      <a-button type="primary" @click="addTable" v-has="'production:term:note:add'" icon="plus"
        >添加</a-button
      >
      <div style="height: 32px"></div>
    </div>
    <a-table
      style="border-top:1px solid rgb(232,232,232)"
      v-has="'production:termtemplate:page:view'"
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickTermsRow"
      :scroll="{ x: 1000, y: 500 }"
      :pagination="ipagination"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 60 }"
      @change="handleTableChange"
    >
      <span slot="sequenceNo" slot-scope="text, record">
        {{ dataSource.findIndex(r => r.id == record.id) + 1 }}
      </span>
      <template  slot="content" slot-scope="text, record">
        <span style="white-space: pre-line;">{{ record.content }}</span>
      </template>
      <span slot="user" slot-scope="text, record">
        <img v-if="record.avatar" :src="record.avatar" alt="" style="width:30px;height:30px;border-radius: 50%;" />
        <span style="margin-left:3px">{{ record.username }}</span>
      </span>
      <span @click.stop slot="action" slot-scope="text, record">
        <a @click="showTable(record)" style="margin-left:15px" >查看详情</a>
        <a v-has="'production:term:note:edit'" @click="editTable(record)" style="margin-left:15px" >编辑</a>
        <a v-has="'production:term:note:delete'" @click="deleteTable(record)" style="margin-left:15px;color:red;">删除</a>
      </span>
    </a-table>
    <terms-note-modal ref="termsNoteModal" :parent="parent" @loadData="loadData" />
  </div>
</template>

<script>
import { postAction, deleteAction } from '@/api/manage'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import termsNoteModal from './terms-note-modal'
export default {
  components: {
    termsNoteModal
  },
  mixins: [JeecgListMixin],
  props: {
    parent: {
      type: Object,
      default: {}
    },
    tab: {
      type: String,
      default: '1'
    }
  },
  watch: {
    tab(n) {
      this.loadData()
    }
  },
  data() {
    return {
      searchText: '',
      isNew: false,
      showTermsModal: false,
      curTempId: 0,
      selectedRowKeys: [],
      clickTermsRow: (record, index) => ({
        on: {
          click: () => {
            /* this.$refs.termsNoteModal.open(record) */
          }
        }
      }),
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 60,
          scopedSlots: { customRender: 'sequenceNo' }
        },
        {
          title: '标题',
          width: 120,
          dataIndex: 'title'
        },
        {
          title: '笔记内容',
          width: 200,
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' }
        },
        {
          title: '录入人员',
          width: 120,
          dataIndex: 'user',
          scopedSlots: { customRender: 'user' }
        },
        {
          title: '录入时间',
          width: 120,
          dataIndex: 'createTime'
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: {
        list: '/term/notes',
        copyBatch: 'productDetail/copy',
        delete: '/term/notes/delete/',
        deleteBatch: '/term/notes/delete/',
        syncPath: 'productDetail/sync'
      }
    }
  },
  methods: {
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      postAction(this.url.list, {
        productionId:this.parent.productionId,
        pageSize: params.pageSize,
        size: params.size,
        current: params.current,
        searchText: this.searchText
      }).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          this.selectedRowKeys = []
          this.selectionRows = []
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(res.data.records || res.data)
            setTimeout(() => {
              this.dataSource = res.data.records || res.data
              if (res.data.total) {
                this.ipagination.total = res.data.total - 0
              } else {
                this.ipagination.total = 0
              }
              this.$emit('loadData', this.dataSource)
            }, 0)
          } else {
            this.dataSource = res.data.records || res.data
            if (res.data.total) {
              this.ipagination.total = res.data.total - 0
            } else {
              this.ipagination.total = 0
            }
            this.$emit('loadData', this.dataSource)
          }
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    addTable() {
      this.$refs.termsNoteModal.open(null)
    },
    del() {},
    editTable(record) {
      this.$refs.termsNoteModal.open(record)
    },
    showTable(record) {
      this.$refs.termsNoteModal.open(record)
      this.$refs.termsNoteModal.show()
    },
    deleteTable(record) {
      let data = []
      let that = this
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/term/notes/delete/' + record.id)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    },
    batchDel() {
      let data = []
      let that = this
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).id)
      })
      data = data.join(',')
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/term/notes/delete/' + data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    }
  },
  created() {}
}
</script>

<style lang="stylus"></style>
